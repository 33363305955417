import React from 'react'
import NavbarHizmetlerUrunler from '../components/NavbarHizmetUrunleri';
import DemirbasSayimHizmetleri2 from "../components/DemirbasSayimHizmetleri2"
import DemirbasSayimHizmetleri3 from "../components/DemirbasSayimHizmetleri3"
import Footer from '../components/Footer';

const DemirbasSayimHizmetleri = () => {
  return (
    <div>
         <NavbarHizmetlerUrunler />
         <DemirbasSayimHizmetleri2 />
         <DemirbasSayimHizmetleri3 />
         <Footer />
      
    </div>
  )
}

export default DemirbasSayimHizmetleri
