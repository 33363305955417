import React from 'react'
import NavbarIletisim from '../components/NavbarIletisim';
import BizeDanisin from '../components/BizeDanisin';
import IletisimFormu from '../components/IletisimFormu';
import Footer from '../components/Footer';


const Iletisim = () => {
  return (
    <div>
      <NavbarIletisim />
      <BizeDanisin />
      <IletisimFormu />
      <Footer />
      
      
    </div>
  )
}

export default Iletisim
