import React from 'react';
import stock from "../images/demirbassayim.jpg"; // Resim dosyanız
import '../css/hizmetler.css'; // CSS dosyasını dahil edin

const DemirbasSayimHizmetleri2 = () => {
  return (
    <div className="container hizmetler ">
      <div className="row align-items-stretch">
        {/* Resim Bölümü */}
        <div className="col-lg-5 col-md-6 col-12 hizmetler-image d-flex">
          <img src={stock} alt="demirbaş sayımı, demirbaş sayim, envanter sayımı, ofis sayımı, varlık sayımı, demirbaş kontrol, sabit kıymet sayımı, ofis ekipmanları, demirbaş yönetimi, en iyi demirbaş sayım firması, demirbaş sayımı yapan şirketler, demirbaş takip, sabit kıymet yönetimi" className="img-fluid" />
        </div>
        {/* Metin Bölümü */}
        <div className="col-lg-7 col-md-6 col-12 d-flex flex-column justify-content-center">
          <h1 className="title">Demirbaş Sayımı</h1>
          <p className="description">
            <strong>Demirbaş sayımı,</strong> işletmenizin varlıklarının detaylı ve doğru bir şekilde 
            kayıt altına alınmasını sağlar. Bürolar, ofisler veya tesislerde bulunan tüm ekipmanların 
            sayımıyla envanterin düzenli ve güvenilir olması garanti edilir.
          </p>
          <p className="description">
            <strong>Demirbaş sayımı süreci,</strong> işletmenizin sahip olduğu tüm sabit kıymetlerin kontrol 
            edilmesini içerir. Bu süreç sayesinde kayıplar önlenir, ekipmanların durumu analiz edilir 
            ve her bir varlık takip edilebilir hale gelir.
          </p>
          <p className="description">
            Demirbaş sayımı hizmetlerimiz, işletmenizin mali tablolarının güvenilirliğini artırır ve 
            kaynaklarınızın etkin bir şekilde kullanılmasını sağlar. Doğru demirbaş yönetimi, işletme 
            verimliliğinizi artırarak maliyetlerinizi düşürmenize yardımcı olur.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemirbasSayimHizmetleri2;
