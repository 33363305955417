import React from 'react';
import stock from "../images/stoksayim1.jpg"; // Resim dosyanız
import '../css/hizmetler.css'; // CSS dosyasını dahil edin

const EkipmanKiralamaHizmetleri2 = () => {
  return (
    <div className="container hizmetler ">
      <div className="row align-items-stretch">
        {/* Resim Bölümü */}
        <div className="col-lg-5 col-md-6 col-12 hizmetler-image d-flex">
          <img src={stock} alt="stok sayımı ekipmanları, stok sayım cihazı kiralama, el terminali kiralama, barkod okuyucu kiralama, sayım cihazı kiralama, envanter sayım ekipmanları, el terminali, sayım programı, barkodlu sayım, sayım cihazları, stok yönetimi ekipmanları" className="img-fluid" />
        </div>
        {/* Metin Bölümü */}
        <div className="col-lg-7 col-md-6 col-12 d-flex flex-column justify-content-center">
          <h1 className="title">Ekipman Kiralama Hizmetleri</h1>
          <p className="description">
            <strong>Stok sayım ekipman kiralama hizmetlerimiz,</strong> işletmenizin ihtiyaç duyduğu profesyonel cihazları ekonomik ve pratik bir şekilde temin etmenizi sağlar. Barkod okuyucular, el terminalleri ve diğer sayım cihazlarıyla hızlı ve hatasız stok yönetimi gerçekleştirebilirsiniz.
          </p>
          <p className="description">
            <strong>Ekipman kiralama,</strong> stok sayım süreçlerinizi kolaylaştırmak ve maliyetlerinizi azaltmak için idealdir. Yüksek kaliteli cihazlarımızla, her türlü envanterin doğru ve eksiksiz şekilde sayılmasını sağlayabilirsiniz. Kullanımı kolay teknolojilerle zaman tasarrufu elde edin.
          </p>
          <p className="description">
            Hizmetimiz, düzenli veya dönemsel stok sayımları için kiralama çözümleri sunar. Stok doğruluğunu artırmak, süreçlerinizi hızlandırmak ve operasyonel verimliliği maksimum düzeye çıkarmak için ekipman kiralama hizmetimizden yararlanın. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default EkipmanKiralamaHizmetleri2;
