import React from 'react'
import NavbarHizmetlerUrunler from '../components/NavbarHizmetUrunleri';
import DepoSayimHizmetleri2 from "../components/DepoSayimHizmetleri2"
import DepoSayimHizmetleri3 from "../components/DepoSayımHizmetleri3"
import Footer from '../components/Footer';


const DepoSayimHizmetleri = () => {
  return (
    <div>
        <NavbarHizmetlerUrunler />
        <DepoSayimHizmetleri2 />
        <DepoSayimHizmetleri3 />
        <Footer />
        
      
    </div>
  )
}

export default DepoSayimHizmetleri
