import React from 'react'
import "../css/footer.css"
import { useNavigate } from 'react-router-dom';
import { FaLinkedin, FaPhoneAlt } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { FaLocationDot, FaSquareInstagram, FaSquareXTwitter } from 'react-icons/fa6';
import LogoSst  from "../images/sstlogo.png"

const Footer = () => {
    const navigate = useNavigate();

    const toPageHome = ( ) => {
        navigate("/")
    }
    const toPageHakkimizda = ( ) => {
        navigate("/hakkimizda")
    }
    const toPageHizmetler = ( ) => {
        navigate("/hizmetlerimiz")
    }
    const toPageIletisim = ( ) => {
        navigate("/iletisim")
    }
    const toPageDepo = ( ) => {
        navigate("/depo-sayim-hizmetleri")
    }
    const toPageMagaza = ( ) => {
        navigate("/magaza-sayim-hizmetleri")
    }
    const toPageDemırbas = ( ) => {
        navigate("/demirbas-sayim-hizmetleri")
    }
    const toPageEkıpman = ( ) => {
        navigate("/ekipman-kiralama-hizmetleri")
    }


  return (
    <div className='sections2'>
        <div class="container ">
            <div class="row">
            <div class="col-lg-3 col-md-6 col-12 text-center py-3">
             <img onClick={toPageHome} className='logoSst' src={LogoSst} alt=""/>
              <p className='text-white text-muted mt-2'>Sürekli değişen ve gelişen iş dünyasında, süreçlerinizi optimize ederek verimliliği artırmak, iş hedeflerinize ulaşmanızı kolaylaştırmak ve çalışanlarınızı en etkin şekilde desteklemek için her zaman yanınızdayız.</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12 text-center py-4">
                <div class="row">
                    <div class="col-12 ">
                        <p className='footerInfo '>KEŞFET</p>    
                    </div>
                    <div class="col-12 liLinks">
                       <li  onClick={toPageHome} className='text-muted'>Anasayfa</li>
                       <li  onClick={toPageHakkimizda} className='text-muted'>Hakkımızda</li>
                       <li onClick={toPageHizmetler}className='text-muted'>Hizmetlerimiz</li>
                       <li onClick={toPageIletisim}className='text-muted'>İletişim</li>
                    </div>
                </div>
                
            </div>
            <div class="col-lg-3 col-md-6 col-12 text-center py-4">
                <div class="row">
                    <div class="col-12 ">
                        <p className='footerInfo '>HİZMETLERİMİZ</p>    
                    </div>
                    <div class="col-12 liLinks">
                       <li  onClick={toPageDepo} className='text-muted'>Depo Sayımı</li>
                       <li  onClick={toPageMagaza} className='text-muted'>Mağaza Sayımı</li>
                       <li onClick={toPageDemırbas}className='text-muted'>Demirbaş Sayımı</li>
                       <li onClick={toPageEkıpman}className='text-muted'>Ekipman Kiralama</li>
                    </div>
                </div>
                
            </div>
            <div class="col-lg-3 col-md-6 col-12 text-center py-4">
                <div class="row">
                    <div class="col-12 ">
                        <p className='footerInfo '>İLETİŞİM</p>    
                    </div>
                    <div class="col-12 liLinks1">
                       <li  className='text-muted'> <strong><FaPhoneAlt className='text-white me-1' /></strong>+90 212 676 19 20</li>
                       <li   className='text-muted'><IoIosMail  className='text-white me-1'/>info@sistemstoksayim.com</li>
                       <li className='text-muted'><FaLocationDot  className='text-white me-1'/>Turgut Özal Mah. Tonguç Baba Cad. No:21 K:3 D:39 Esenyurt/İstanbul</li>
                       <li className='text-muted'>
                            
                            <a href="https://tr.linkedin.com/company/si%CC%87stem-stok-sayim">
                            <span>
                            <FaLinkedin  className='text-white me-2 loglinks'/>
                            </span>
                            </a>
                            <a href="https://www.instagram.com/sistemstoksayim/">
                            <span >
                            <FaSquareInstagram className='text-white me-2 loglinks'/>
                            </span>
                            </a>
                            
                         
                       </li>
                    </div>
                </div>
                
            </div>
            </div>
          
        </div>
    </div>
  )
}

export default Footer
