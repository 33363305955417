import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';

import Hizmetlerimiz from '../pages/Hizmetlerimiz';
import DepoSayimHizmetleri from '../pages/DepoSayimHizmetleri';
import MagazaSayimHizmetleri from '../pages/MagazaSayimHizmetleri';
import DemirbasSayimHizmetleri from '../pages/DemirbasSayimHizmetleri';
import EkipmanKiralama from '../pages/EkipmanKiralama';
import Iletisim from '../pages/Iletisim';
import Hakkımızda from '../pages/Hakkımızda';

const RouterConfig = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/hakkimizda' element={<Hakkımızda />} />
      <Route path='/hizmetlerimiz' element={<Hizmetlerimiz />} />
      <Route path='/iletisim' element={<Iletisim />} />
      <Route path='/depo-sayim-hizmetleri' element={<DepoSayimHizmetleri />} />
      <Route path='/magaza-sayim-hizmetleri' element={<MagazaSayimHizmetleri />} />
      <Route path='/demirbas-sayim-hizmetleri' element={<DemirbasSayimHizmetleri />} />
      <Route path='/ekipman-kiralama-hizmetleri' element={<EkipmanKiralama />} />
    </Routes>
  );
}

export default RouterConfig;
