import React from "react";
import { IoIosMail } from "react-icons/io";
import { FaPhoneVolume } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import "../css/bizeDanisin.css";

const BizeDanisin = () => {
  return (
    <div className="custom-container my-5">
      <div className="custom-row">
        <div className="custom-title">Bizimle Geleceğinizi İnşa Edin!</div>
        <p className="custom-paragraph">
          Stok yönetiminizi kolaylaştıracak çözümlerimizle tanışın! İşinizi bir adım
          öteye taşımak için bizimle iletişime geçin.
        </p>
      </div>

      <div className="custom-row row">
        <div className="col-lg-4 col-md-6 col-12">
          <div className="custom-info">
            <IoIosMail className="custom-icon" />
            <div className="custom-info-text">info@sistemstoksayim.com</div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="custom-info">
            <FaPhoneVolume className="custom-icon" />
            <div className="custom-info-text">+90 212 676 19 20</div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="custom-info">
            <FaLocationDot className="custom-icon" />
            <div className="custom-info-text">
            Turgut Özal Mah. Tonguç Baba Cad. No:21 K:3 D:39 Esenyurt/İstanbul
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default BizeDanisin;
