import React, { useState } from "react";
import "../css/sss.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Sss from "../images/sss.jpg"

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: "Stok Sayımı Nedir?",
      answer:
        "Stok sayımı, bir işletmenin sahip olduğu ürünlerin miktarını tespit etmek için yapılan fiziksel denetim işlemidir. Bu süreç, ürünlerin ambalaj ve sergilenme özellikleri, satış hızları ve süreçlerine göre farklılık gösterir. Sevkiyat aşamasında, ürünler mağazaya ulaşmadan sayım yapılmamalıdır.",
    },
    { question: "Stok sayım ne sıklıkla yapılabilir?", answer: "Stok sayımı, genellikle yılda bir veya iki kez yapılır, ancak yoğun satış yapan işletmelerde bu süreç daha sık yapılabilir. Bazı işletmeler, ayda bir veya çeyrek dönemlerde sayım yaparak envanterdeki değişiklikleri daha düzenli takip edebilir. İhtiyaç ve işin büyüklüğüne göre sayım sıklığı belirlenir.." },
    { question: "Stok sayım işleminin avantajları nelerdir?", answer: "Stok sayımının avantajları, envanterin doğru ve güncel tutulmasını sağlar, stok hatalarını tespit eder, maliyetleri kontrol altında tutar, satış ve üretim planlamasını iyileştirir. Ayrıca, kayıp ve hasarlı ürünleri belirleyerek, işletmenin verimliliğini artırır ve müşteri memnuniyetini sağlar." },
    { question: "Stok Sayımları hangi zamanlarda yapılır?", answer: "Stok sayımı, genellikle yıl sonu kapanışında veya belirli aralıklarla yapılmalıdır. Ayrıca, ürün alımı veya sevkiyatından önce, büyük indirim dönemlerinde ve stokta önemli değişiklikler olduğunda da sayım yapılması önerilir. Sayım, mağazaya ulaşmayan ürünlerde yapılmamalıdır." },
    { question: "Sayım süreci ve raporlama işlemi nasıl yapılır?", answer: "Sayım süreci, ürünlerin yerinde kontrol edilerek miktarlarının kaydedilmesiyle başlar. Ekipler, her ürün için belirli kriterlere göre sayım yapar. Ardından, sayım sonuçları bir raporda toplanarak, envanter farkları analiz edilir. Bu rapor, yönetim tarafından incelenir ve gerekli aksiyonlar alınır" },
    { question: "Stok sayım işleminde nelere dikkat edilmelidir?", answer: "Stok sayımında dikkat edilmesi gerekenler, doğru ekipman kullanımı, dikkatli ürün yerleşimi, sayım sırasında kesintisiz odaklanma ve güncel envanter verilerinin referans alınmasıdır. Ayrıca, sayımın tamamlanması sonrası verilerin doğru şekilde raporlanması ve karşılaştırılması da önemlidir." },
  ];

  return (
    <div className="faq-container">
      <div className="faq-content">
        <h3>S.S.S</h3>
        <h1 className="border-bottom">Sıkça Sorulan Sorular</h1>
        <ul className="faq-list">
          {faqData.map((item, index) => (
            <li
              key={index}
              className={`faq-item ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <button
                onClick={() => toggleAnswer(index)}
                className="faq-question"
              >
                {item.question}
                {activeIndex === index ? (
                  <FaChevronUp className="faq-icon" />
                ) : (
                  <FaChevronDown className="faq-icon" />
                )}
              </button>
              {activeIndex === index && <p className="faq-answer">{item.answer}</p>}
            </li>
          ))}
        </ul>
      </div>
      <div className="faq-image">
        <img src={Sss} alt="stok sayım,stok sayım firması,
        stoksayım firması" />
      </div>
    </div>
  );
};

export default FaqSection;
