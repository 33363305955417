import React from 'react'

const IletisimFormu = () => {
  return (
    <div>
      <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-7 col-12">
              
            </div>
            <div class="col-lg-3 col-md-5 col-12">

            </div>
        </div>
      </div>
      
    </div>
  )
}

export default IletisimFormu
