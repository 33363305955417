import React from "react";
import Navbar from "../components/Navbar";
import Sstinfo from "../components/Sstinfo";
import Hizmetler from "../components/Hizmetler";
import Details from "../components/Details";
import Banner from "../components/Banner";
import Sss from "../components/Sss";
import Product from "../components/Product";
import Idea from "../components/AddIdea";
import FooterAlt from "../components/footerTop";
import Footer from "../components/Footer";



const Home = () => {
  return (
    <div>
      <Navbar />
      <Hizmetler />
      <Sstinfo />
      <Sss />
      <Details />
      <Banner />
     <FooterAlt />
     <Footer />
      
     

   
    </div>
  );
};

export default Home;
