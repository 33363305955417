import React from 'react'
import NavbarHizmetler from '../components/NavbarHizmetler'
import Product from '../components/Product'
import Footer from '../components/Footer'

const Hizmetlerimiz = () => {
  return (
    <div>
      <NavbarHizmetler />
      <Product />
      <Footer />
    </div>
  )
}

export default Hizmetlerimiz
