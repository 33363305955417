import React from "react";
import "../css/sstInfo.css";
import Hizmet1 from "../images/stoksayım44.jpg";
import Hizmet2 from "../images/stoksayım11.jpg";
import Hizmet3 from "../images/stoksayım55.jpg";
import Hizmet4 from "../images/stok4.jpg";
import { useNavigate } from "react-router-dom";

const Sstinfo = () => {
  const navigate = useNavigate();

  const toPageDepoSayimi = ( ) => {
    navigate ("/depo-sayim-hizmetleri")
  }

  const toPageMagazaSayim = ( ) => {
    navigate ("/magaza-sayim-hizmetleri")
  }
  const toPageDemirbasSayim = ( ) => {
    navigate ("/demirbas-sayim-hizmetleri")
  }
  const toPageEkipmanKiralama = ( ) => {
    navigate ("/ekipman-kiralama-hizmetleri")
  }

  return (
    <div className="sstinfo ">
      <div className="container">
        <div className="titleHizmetler">
          <h2 className="border-bottom">NELER YAPIYORUZ?</h2>
        </div>
        <div className="row rowaa">
          <div onClick={toPageDepoSayimi} className="col-lg-3 col-md-4 col-12 itemColumn itemTolinks">
            <img
              src={Hizmet3}
              alt="stok sayım firması,stok sayim firmasi,stok sayım firmaları,stok sayim firmalari,envanter sayım,envanter sayim,stok envanter sayım,stok envanter sayim,stok kontrol,stok envanter kontrol,stok,envanter,envanter sayım firması,envanter sayim firmasi,envanter sayım firmaları,envanter sayim firmalari,stok kontrol,stok envanter kontrol,market sayımı,tekstil sayımı,eczane sayımı,eczane sayımı yapan firmalar,depo sayımı,depo sayimi,depo stok sayımı,sayım yapan firmalar,stok sayım şirketleri,stok sayim şirketleri,stok envanter sayım şirketleri,sayman,sayım personeli,sayım elemanı,stok sayım personeli,stok sayım elemanı,stok sayım programı,sayım programı,el terminali,elterminali,ptc,stok sayım nedir,stok sayım nasıl yapılır,sistem,sistem stok,sistem stoksayım,sayımcı,sistem stok sayım,sistem stok sayim,stok kontrol,stok düzeltme,el terminali kiralama,sayım raporu,sayim raporu,sayım sonuç raporu,sayım sonuç raporları,sene sonu sayım,sene sonu sayim,sene sonu stok sayım,yıl sonu sayımı,yıl sonu sayımları,baskın sayım,baskin sayim,ani sayım,ani sayim,barkodsuz sayım,barkodsuz sayim,gizli müşteri,benzin istasyonu sayımı,akaryakıt istasyonu sayımı,istasyon sayımı,benzinci sayımı,ayakkabı mağazası sayımı,kırtasiye sayımı,kitap evi sayımı,en iyi stok sayım firması,en iyi stok sayım şirketi,sayım nasıl yapılır,sayım zamanı,sayım planlama,sayım operasyonları,stok sayım planlama,sayım ekibi,stok sayım ekibi"
              className="itemImage"
            />
            <h4>Depo Sayım Hizmetleri</h4>
            <p className="itemDescription">
            Depo sayım hizmetleri, stokların doğru ve düzenli bir şekilde izlenmesini sağlayarak, envanterin doğruluğunu artıran kritik bir süreçtir.
            </p>
            <a onClick={toPageDepoSayimi}  className="btnContinue">
              Devamı
            </a>
          </div>
          
          <div onClick={toPageMagazaSayim }  className="col-lg-3 col-md-4 col-12 itemColumn itemTolinks">
            <img
              src={Hizmet1}
              alt="stok sayım firması,stok sayim firmasi,stok sayım firmaları,stok sayim firmalari,envanter sayım,envanter sayim,stok envanter sayım,stok envanter sayim,stok kontrol,stok envanter kontrol,stok,envanter,envanter sayım firması,envanter sayim firmasi,envanter sayım firmaları,envanter sayim firmalari,stok kontrol,stok envanter kontrol,market sayımı,tekstil sayımı,eczane sayımı,eczane sayımı yapan firmalar,depo sayımı,depo sayimi,depo stok sayımı,sayım yapan firmalar,stok sayım şirketleri,stok sayim şirketleri,stok envanter sayım şirketleri,sayman,sayım personeli,sayım elemanı,stok sayım personeli,stok sayım elemanı,stok sayım programı,sayım programı,el terminali,elterminali,ptc,stok sayım nedir,stok sayım nasıl yapılır,sistem,sistem stok,sistem stoksayım,sayımcı,sistem stok sayım,sistem stok sayim,stok kontrol,stok düzeltme,el terminali kiralama,sayım raporu,sayim raporu,sayım sonuç raporu,sayım sonuç raporları,sene sonu sayım,sene sonu sayim,sene sonu stok sayım,yıl sonu sayımı,yıl sonu sayımları,baskın sayım,baskin sayim,ani sayım,ani sayim,barkodsuz sayım,barkodsuz sayim,gizli müşteri,benzin istasyonu sayımı,akaryakıt istasyonu sayımı,istasyon sayımı,benzinci sayımı,ayakkabı mağazası sayımı,kırtasiye sayımı,kitap evi sayımı,en iyi stok sayım firması,en iyi stok sayım şirketi,sayım nasıl yapılır,sayım zamanı,sayım planlama,sayım operasyonları,stok sayım planlama,sayım ekibi,stok sayım ekibi"
              className="itemImage"
            />
            <h4>Mağaza Sayım Hizmetleri</h4>
            <p className="itemDescription">
            Mağaza sayım hizmetleri, stokların doğru ve düzenli bir şekilde sayılmasını sağlayarak, envanter yönetimini iyileştirir ve işletmenin verimliliğini maksimum seviyeye çıkarır.
            </p>
            <a onClick={toPageMagazaSayim }   className="btnContinue">
              Devamı
            </a>
          </div>
          <div onClick={toPageDemirbasSayim }  className="col-lg-3 col-md-4 col-12 itemColumn itemTolinks">
            <img
              src={Hizmet2}
              alt="stok sayım firması,stok sayim firmasi,stok sayım firmaları,stok sayim firmalari,envanter sayım,envanter sayim,stok envanter sayım,stok envanter sayim,stok kontrol,stok envanter kontrol,stok,envanter,envanter sayım firması,envanter sayim firmasi,envanter sayım firmaları,envanter sayim firmalari,stok kontrol,stok envanter kontrol,market sayımı,tekstil sayımı,eczane sayımı,eczane sayımı yapan firmalar,depo sayımı,depo sayimi,depo stok sayımı,sayım yapan firmalar,stok sayım şirketleri,stok sayim şirketleri,stok envanter sayım şirketleri,sayman,sayım personeli,sayım elemanı,stok sayım personeli,stok sayım elemanı,stok sayım programı,sayım programı,el terminali,elterminali,ptc,stok sayım nedir,stok sayım nasıl yapılır,sistem,sistem stok,sistem stoksayım,sayımcı,sistem stok sayım,sistem stok sayim,stok kontrol,stok düzeltme,el terminali kiralama,sayım raporu,sayim raporu,sayım sonuç raporu,sayım sonuç raporları,sene sonu sayım,sene sonu sayim,sene sonu stok sayım,yıl sonu sayımı,yıl sonu sayımları,baskın sayım,baskin sayim,ani sayım,ani sayim,barkodsuz sayım,barkodsuz sayim,gizli müşteri,benzin istasyonu sayımı,akaryakıt istasyonu sayımı,istasyon sayımı,benzinci sayımı,ayakkabı mağazası sayımı,kırtasiye sayımı,kitap evi sayımı,en iyi stok sayım firması,en iyi stok sayım şirketi,sayım nasıl yapılır,sayım zamanı,sayım planlama,sayım operasyonları,stok sayım planlama,sayım ekibi,stok sayım ekibi"
              className="itemImage"
            />
            <h4>Demirbaş Sayım Hizmetleri</h4>
            <p className="itemDescription">
            Demirbaş sayımları, taşınabilir varlıkların düzenli kontrol edilmesini sağlayarak, envanterin doğruluğunu güçlendiren önemli bir süreçtir.
            </p>
            <a onClick={toPageDemirbasSayim }  className="btnContinue">
              Devamı
            </a>
          </div>
          <div onClick={toPageEkipmanKiralama} className="col-lg-3 col-md-4 col-12 itemColumn itemTolinks">
            <img
              src={Hizmet4}
              alt="stok sayım firması,stok sayim firmasi,stok sayım firmaları,stok sayim firmalari,envanter sayım,envanter sayim,stok envanter sayım,stok envanter sayim,stok kontrol,stok envanter kontrol,stok,envanter,envanter sayım firması,envanter sayim firmasi,envanter sayım firmaları,envanter sayim firmalari,stok kontrol,stok envanter kontrol,market sayımı,tekstil sayımı,eczane sayımı,eczane sayımı yapan firmalar,depo sayımı,depo sayimi,depo stok sayımı,sayım yapan firmalar,stok sayım şirketleri,stok sayim şirketleri,stok envanter sayım şirketleri,sayman,sayım personeli,sayım elemanı,stok sayım personeli,stok sayım elemanı,stok sayım programı,sayım programı,el terminali,elterminali,ptc,stok sayım nedir,stok sayım nasıl yapılır,sistem,sistem stok,sistem stoksayım,sayımcı,sistem stok sayım,sistem stok sayim,stok kontrol,stok düzeltme,el terminali kiralama,sayım raporu,sayim raporu,sayım sonuç raporu,sayım sonuç raporları,sene sonu sayım,sene sonu sayim,sene sonu stok sayım,yıl sonu sayımı,yıl sonu sayımları,baskın sayım,baskin sayim,ani sayım,ani sayim,barkodsuz sayım,barkodsuz sayim,gizli müşteri,benzin istasyonu sayımı,akaryakıt istasyonu sayımı,istasyon sayımı,benzinci sayımı,ayakkabı mağazası sayımı,kırtasiye sayımı,kitap evi sayımı,en iyi stok sayım firması,en iyi stok sayım şirketi,sayım nasıl yapılır,sayım zamanı,sayım planlama,sayım operasyonları,stok sayım planlama,sayım ekibi,stok sayım ekibi"
              className="itemImage"
            />
            <h4>Ekipman Kiralama</h4>
            <p className="itemDescription">
            Ekipman kiralama hizmetleri, işletmelere ihtiyaç duydukları araç ve gereçleri esnek koşullarla sunarak, maliyetleri düşürür ve operasyonel verimliliği artırır.
            </p>
            <a onClick={toPageEkipmanKiralama}  className="btnContinue">
              Devamı
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sstinfo;
