import React from 'react'
import NavbarPage from '../components/NavbarPage'
import CompaniesInfo from '../components/CompaniesInfo'
import InfoAtBannner from '../components/InfoAtbanner'
import Footer from '../components/Footer'

const Hakkımızda = () => {
  return (
    <div>
      <NavbarPage />
      <CompaniesInfo />
      <InfoAtBannner />
      <Footer />
    </div>
  )
}

export default Hakkımızda
