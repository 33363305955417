import React, { useState } from 'react';
import '../css/navbarHizmetÜrünler.css';
import HakkimizdaSst from "../images/hakkimizda.webp";
import { FaHome, FaInfoCircle } from 'react-icons/fa'; // Importing icons
import { useNavigate } from 'react-router-dom';
import LogoSst from "../images/sstlogo.png"

function NavbarIletisim() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const TopageHome = () => {
    navigate("/")
  }
  const TopageHakkimizda = () => {
    navigate("/hakkimizda")
  }
  const toPageHome = () => {
    navigate("/")
  }
  
  const toPageHizmetler = () => {
    navigate("/hizmetlerimiz")
  }
  
  const toPageIletisim = () => {
    navigate("/iletisim")
  }
  

  return (
    <div className="App">
      <header className="header">
        <div className="image-container">
          <img src={HakkimizdaSst} alt="background" className="background-image" />
          <div className="overlay">
            <h1 className='border-bottom'>Kurumsal</h1>
            <p className="links">
              <span onClick={toPageHome}    className="link-item"><FaHome /> Anasayfa</span> |
              <span onClick={toPageHizmetler} className="link-item"><FaInfoCircle /> İletişim</span>
            </p>
          </div>
        </div>
        <nav className={`navbar ${menuOpen ? 'active' : ''}`}>
          <div className="containeraaa">
            <div onClick={toPageHome} className="logo"><img src={LogoSst} alt="stoksayım,stoksayim,stok sayım,stok sayim,stoksayım firması,stoksayım firmasi,stok sayım firması,stok sayim firmasi,stok sayım firmaları,stok sayim firmalari,envanter sayım,envanter sayim,stok envanter sayım,stok envanter sayim,stok kontrol,stok envanter kontrol,stok,envanter,envanter sayım firması,envanter sayim firmasi,envanter sayım firmaları,envanter sayim firmalari,stok kontrol,stok envanter kontrol,market sayımı,tekstil sayımı,eczane sayımı,eczane sayımı yapan firmalar,depo sayımı,depo sayimi,depo stok sayımı,sayım yapan firmalar,stok sayım şirketleri,stok sayim şirketleri,stok envanter sayım şirketleri,sayman,sayım personeli,sayım elemanı,stok sayım personeli,stok sayım elemanı,stok sayım programı,sayım programı,el terminali,elterminali,ptc,stok sayım nedir,stok sayım nasıl yapılır,sistem,sistem stok,sistem stoksayım,sayımcı,sistem stok sayım,sistem stok sayim,stok kontrol,stok düzeltme,el terminali kiralama,sayım raporu,sayim raporu,sayım sonuç raporu,sayım sonuç raporları,sene sonu sayım,sene sonu sayim,sene sonu stok sayım,yıl sonu sayımı,yıl sonu sayımları,baskın sayım,baskin sayim,ani sayım,ani sayim,barkodsuz sayım,barkodsuz sayim,gizli müşteri,benzin istasyonu sayımı,akaryakıt istasyonu sayımı,istasyon sayımı,benzinci sayımı,ayakkabı mağazası sayımı,kırtasiye sayımı,kitap evi sayımı,en iyi stok sayım firması,en iyi stok sayım şirketi,sayım nasıl yapılır,sayım zamanı,sayım planlama,sayım operasyonları,stok sayım planlama,sayım ekibi,stok sayım ekibi"/></div>
            <ul className="nav-links">
              <li onClick={TopageHome}><a >Anasayfa</a></li>
              <li onClick={TopageHakkimizda} ><a>Hakkımızda</a></li>
              <li onClick={toPageHizmetler} className="" ><a>Hizmetler</a></li>
              <li onClick={toPageIletisim} className='active'><a >İletişim</a></li>
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
              ☰
            </div>
            <div className="close-menu" onClick={toggleMenu}>
              ✖
            </div>
          </div>
        </nav>
      </header>
      <main>
        {/* Diğer içerikler buraya gelecek */}
      </main>
    </div>
  );
}
export default NavbarIletisim;
