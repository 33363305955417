import React, { useState, useEffect, useRef } from "react";
import "../css/details.css";

const Details = () => {
  const statsData = [
    {
      id: 1,
      value: 4500,
      title: "Personel",
      description: "Aylık alanında uzman sayım personeli",
      suffix: "+",
    },
    {
      id: 2,
      value: 98,
      title: "Müşteri Memnuniyeti",
      description: "Bizimle, her zaman bir adım öndesiniz.",
      suffix: "%",
    },
    {
      id: 3,
      value: 81,
      title: "İle Yaygın Hizmet",
      description: "7 Bölgesel Ofis ve 81 ile yaygın hizmet ağı.",
      suffix: "",
    },
    {
      id: 4,
      value: 170,
      title: "Kurumsal Referans",
      description: "Alanında uzman firmaların referansı olmayı başardık.",
      suffix: "+",
    },
  ];

  const [count, setCount] = useState(statsData.map(() => 0)); // Başlangıçta sayılar 0
  const [animationStarted, setAnimationStarted] = useState(false); // Animasyon başlama durumu

  const statRefs = useRef([]); // Referansları tutacak bir dizi

  // IntersectionObserver ile animasyonu tetiklemek
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Eğer stat card görünürse, animasyonu başlat
          if (entry.isIntersecting) {
            setAnimationStarted(true);
          }
        });
      },
      { threshold: 0.5 } // Öğenin en az %50'si göründüğünde tetiklesin
    );

    // Her bir stat card için observer'ı başlat
    statRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect(); // Cleanup
    };
  }, []);

  // Animasyon için sayıları arttıran useEffect
  useEffect(() => {
    if (animationStarted) {
      const interval = setInterval(() => {
        setCount((prevCount) =>
          prevCount.map((val, index) => {
            if (val < statsData[index].value) {
              return val + Math.ceil(statsData[index].value / 100); // Sayıyı artır
            }
            return statsData[index].value; // Hedef sayıya ulaşıldığında dur
          })
        );
      }, 30); // 30 ms aralıkla sayıyı arttır

      return () => clearInterval(interval); // Cleanup
    }
  }, [animationStarted]);

  return (
    <div className="container stats-container">
      <div className="row">
        {statsData.map((stat, index) => (
          <div
            className="col-lg-3 col-md-6 col-12 d-flex justify-content-center"
            key={stat.id}
            ref={(el) => (statRefs.current[index] = el)} // Her stat card için referans
          >
            <div className="stat-card">
              <h2 className="stat-value border-bottom">
                {stat.suffix && `${stat.suffix} `}
                {count[index]}
              </h2>
              <h3 className="stat-title">{stat.title}</h3>
              <p className="stat-description">{stat.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Details;
