import React from 'react'
import NavbarHizmetlerUrunler from '../components/NavbarHizmetUrunleri';
import EkipmanKiralamaHizmetleri2 from '../components/EkipmanKiralamaHizmetleri2';
import EkipmanKiralamaHizmetleri3 from '../components/EkipmanKiralamaHizmetleri3';
import Footer from '../components/Footer';

const EkipmanKiralama = () => {
  return (
    <div>
      <NavbarHizmetlerUrunler />
      <EkipmanKiralamaHizmetleri2 />
      <EkipmanKiralamaHizmetleri3 />
      <Footer />
    </div>
  )
}

export default EkipmanKiralama
