import React from 'react';
import stock from "../images/stok1.jpg"; // Resim dosyanız
import '../css/hizmetler.css'; // CSS dosyasını dahil edin

const Hizmetler = () => {
  return (
    <div className="container hizmetler ">
      <div className="row align-items-stretch">
        {/* Resim Bölümü */}
        <div className="col-lg-5 col-md-6 col-12 hizmetler-image d-flex">
          <img src={stock} alt="stoksayım,stoksayim,stok sayım,stok sayim,stoksayım firması,stoksayım firmasi,stok sayım firması,stok sayim firmasi,stok sayım firmaları,stok sayim firmalari,envanter sayım,envanter sayim,stok envanter sayım,stok envanter sayim,stok kontrol,stok envanter kontrol,stok,envanter,envanter sayım firması,envanter sayim firmasi,envanter sayım firmaları,envanter sayim firmalari,stok kontrol,stok envanter kontrol,market sayımı,tekstil sayımı,eczane sayımı,eczane sayımı yapan firmalar,depo sayımı,depo sayimi,depo stok sayımı,sayım yapan firmalar,stok sayım şirketleri,stok sayim şirketleri,stok envanter sayım şirketleri,sayman,sayım personeli,sayım elemanı,stok sayım personeli,stok sayım elemanı,stok sayım programı,sayım programı,el terminali,elterminali,ptc,stok sayım nedir,stok sayım nasıl yapılır,sistem,sistem stok,sistem stoksayım,sayımcı,sistem stok sayım,sistem stok sayim,stok kontrol,stok düzeltme,el terminali kiralama,sayım raporu,sayim raporu,sayım sonuç raporu,sayım sonuç raporları,sene sonu sayım,sene sonu sayim,sene sonu stok sayım,yıl sonu sayımı,yıl sonu sayımları,baskın sayım,baskin sayim,ani sayım,ani sayim,barkodsuz sayım,barkodsuz sayim,gizli müşteri,benzin istasyonu sayımı,akaryakıt istasyonu sayımı,istasyon sayımı,benzinci sayımı,ayakkabı mağazası sayımı,kırtasiye sayımı,kitap evi sayımı,en iyi stok sayım firması,en iyi stok sayım şirketi,sayım nasıl yapılır,sayım zamanı,sayım planlama,sayım operasyonları,stok sayım planlama,sayım ekibi,stok sayım ekibi" className="img-fluid" />
        </div>
        {/* Metin Bölümü */}
        <div className="col-lg-7 col-md-6 col-12 d-flex flex-column justify-content-center">
          <h1 className="title">Sistem Stok Sayım</h1>
          <p className="description">
            İşletmenizin envanter doğruluğunu sağlamak, kayıpları önlemek ve stok
            seviyelerini kontrol altında tutmak için buradayız. Profesyonel ekibimizle,
            ihtiyaçlarınıza özel çözümler sunuyor ve stok yönetiminizi kolaylaştırıyoruz.
          </p>
          <p className="description">
            Hedefimiz, minimum maliyetle maksimum verimlilik sağlayarak işletmenizin büyüme
            yolculuğunda size destek olmaktır. Düzenli stok sayımı süreçlerimizle, finansal
            tablolarınızın güvenilir olmasını ve müşteri taleplerinin eksiksiz karşılanmasını
            sağlıyoruz.
          </p>
          <p className="description">
            Operasyonel verimliliği artırmak ve işletmenizi bir adım öne taşımak için bizimle
            çalışmaya hazır mısınız?
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hizmetler;
