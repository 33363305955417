import React from 'react'
import NavbarHizmetlerUrunler from '../components/NavbarHizmetUrunleri';
import MagazaSayimHizmetleri2 from "../components/MagazaSayimHizmetleri2"
import MagazaSayimHizmetleri3 from "../components/MagazaSayimHizmetleri3"
import Footer from '../components/Footer';

const MagazaSayimHizmetleri = () => {
  return (
    <div>
      <NavbarHizmetlerUrunler />
      <MagazaSayimHizmetleri2 />
      <MagazaSayimHizmetleri3 />
      <Footer />
      
    </div>
  )
}

export default MagazaSayimHizmetleri
