import React from 'react';
import "../css/companıesInfo.css";  // Ensure you have styles for .card, .card-body, etc.
import { FaBullhorn, FaBullseye, FaFlagCheckered } from 'react-icons/fa';


const CompaniesInfo = () => {
  return (
    <div className='container mt-5'>
        <div class="title ">
            <p className='text-muted text-center  d-block border-bottom yazibaslik'>
                    Sistem Stok Sayım
            </p>
            <p className='text-center yaziInfo'>
                Alanında Uzman Personel Kadromuzla;
            </p>
        </div>

        <div className="row">
          {/* Vizyonumuz Card */}
          <div className="col-lg-4 col-md-6 col-12 text-center">
            <div className="card shadow-sm d-flex flex-column">
              <div className="card-body">
                <div className="span-icons text-center">
                  <FaBullseye className='icon ' />
                  <h5 className='border-bottom'>Vizyonumuz</h5>
                </div>
                <p>Evrensel değerlere bağlı, kurumsal sosyal sorumluluk yükümlülüklerini yerine getiren bir şirket olarak faaliyet göstermek.</p>
              </div>
            </div>
          </div>

          {/* Misyonumuz Card */}
          <div className="col-lg-4 col-md-6 col-12 text-center">
            <div className="card shadow-sm d-flex flex-column">
              <div className="card-body">
                <div className="span-icons text-center">
                  <FaBullhorn  className='icon' />
                  <h5 className='border-bottom'>Misyonumuz</h5>
                </div>
                <p>'Müşterilerimiz için değer üreten; fark oluşturan çözüm odaklı, rekabetçi, öncü, güçlü ve saygın bir dünya şirketi olmak.</p>
              </div>
            </div>
          </div>

          {/* Hedefimiz Card */}
          <div className="col-lg-4 col-md-6 col-12 text-center">
            <div className="card shadow-sm d-flex flex-column">
              <div className="card-body">
                <div className="span-icons text-center">
                  <FaFlagCheckered className='icon' />
                  <h5 className='border-bottom'>Hedefimiz</h5>
                </div>
                <p>Global pazarda lider bir stok sayım firması olarak, yenilikçi çözümler ve müşteri odaklı hizmetle büyümek.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  );
}

export default CompaniesInfo;
