import React from "react";
import "../css/banner.css";

import logo4 from "../images/logo4.png";

import logo13 from "../images/logo13.jpg";
import logo14 from "../images/logo14.png";
import logo15 from "../images/logo15.png";

import logo19 from "../images/logo19.png";
import logo20 from "../images/logo20.png";

import logo25 from "../images/logo25.png";
import logo26 from "../images/logo26.png";
import logo27 from "../images/logo27.jpg";
import logo31 from "../images/logo31.png";
import logo32 from "../images/logo32.png";
import logo33 from "../images/logo33.png";
import logo34 from "../images/logo34.jpg";

import logo36 from "../images/logo36.png";
import logo37 from "../images/logo37.png";
import logo38 from "../images/logo38.jpg";

import logo40 from "../images/logo40.jpg";
import logo41 from "../images/logo41.png";
import logo42 from "../images/logo42.png";

import penti from "../images/logopentı.png";
import lufian from "../images/lufian.jpg";
import çetin from "../images/çetin.png";
import sport from "../images/sport.png";
import hotiç from "../images/hotic_logo.png";
import baby from "../images/baby.png";
import ktky from "../images/ktky.jpg";
import accort from "../images/Accort-Logo.png";
import trendypol from "../images/trednyol.png";
import adl from "../images/adl-logo.png";
import suzukı from "../images/suzukı.png";
import vıgo from "../images/vıgo.jpg";
import tff from "../images/tff02.png";
import reflect from "../images/reflect.png";
import yesılKundura from "../images/yesılkundura.png";
import depomaxx from "../images/depomaxx.png";
import aker from "../images/aker.jpg";
// Örnek logo verileri
const logos = [
  logo4,
  logo38,
  penti,
  lufian,
  logo37,
  logo40,
  logo42,
  logo34,
  çetin,
  sport,
  hotiç,
  baby,
  ktky,
  logo27,
  accort,
  logo41,
  trendypol,
  logo31,
  logo33,
  adl,
  logo19,
  logo13,
  logo14,
  logo15,
  logo20,
  suzukı,
  logo25,
  logo32,
  logo26,
  vıgo,
  tff,
  logo36,
  reflect,
  yesılKundura,
  depomaxx,
  aker,
];

const Banner = () => {
  return (
    <section className="logo-slider">
      <div class="text-baslik">
        <div class="h3 text-center mb-3  text-white border-bottom">
          Referanslarımız
        </div>
      </div>
      <div className="slider">
        {/* Logoların yalnızca bir kez döngüye girmesi */}

        {logos.map((logo, index) => (
          <div className="logo-card" key={index}>
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Banner;
