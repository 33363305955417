import React, { useState } from 'react';
import '../css/navbar.css';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Logo from "../images/sstlogo.png"


const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  
  const toPageHome = () => {
    navigate("/")
  }
  const toPageHakkimizda = () => {
    navigate("/hakkimizda")
  }
  const toPageHizmetlerimiz = () => {
    navigate("/hizmetlerimiz")
  }
  const toPageIletisim = () => {
    navigate("/iletisim")
  }
  
 
  return (
    <div className='mb-5'>
      <nav className="navbar ">
        
        <a  onClick={toPageHome}  className="logo ">
          <img className='logopng' src={Logo} alt=""/>
         <p className='sstLogo'>Sistem Stok Sayım</p> </a>
        <div
          className={`toggler ${toggle ? 'active' : ''}`}
          onClick={() => setToggle(!toggle)}
        >
          <div className="toggler-bar"></div>
          <div className="toggler-bar"></div>
          <div className="toggler-bar"></div>
        </div>
        <ul className={`nav-links ${toggle ? 'active' : ''}`}>
          
          <li onClick={toPageHakkimizda} className='clickEfet clıckEfectka'>
            <a >Hakkımızda</a>
          </li>
          <li onClick={toPageHizmetlerimiz} className='clickEfet clıckEfectka' >
            <a >Hizmetler</a>
          </li>
          <li onClick={toPageIletisim}  className='clickEfet clıckEfectka '>
            <a >İletişim</a>
          </li>
        </ul>
      </nav>
      <div className="hero">
        <div className="overlay"></div>
        <div className="content">
          <h1>Her Zaman, Daha İyiye</h1>
          <p>
          Stok sayım hizmetleri alanında sunduğumuz özel çözümler ile iş süreçlerinizi hızlandırıyor, envanter doğruluğunu artırıyor ve verimliliğinizi en üst seviyeye çıkarıyoruz. Şirketinizin operasyonel verimliliğini güçlendiriyor, güvenilir ve zamanında sonuçlar sunarak geleceğe sağlam adımlar atmanıza destek oluyoruz.
          </p>
          <button onClick={toPageHizmetlerimiz} className="btn"><FaChevronRight /> Hizmetlerimiz</button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
